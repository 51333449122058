import Body from "../../styles/components/Body"
import Button from "../../styles/components/Button"
import React from "react"
import styled from "styled-components"
import Section from "../../styles/components/Section"

//todo: shared with testimonial
const SectionPad = styled(Section)` 
  margin-top: -40px;
  padding-top: 0px;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0px auto;
`

const ButtonContainer = styled.div`
  margin-top: 3em;
  text-align: center;
`

const WorkWithUs = ({ email, form }) => (
    <SectionPad>
        <Content>
            <Body>
            Think you could take advantage of our process in your business? Email us
            at <a href={`mailto:${email}`}>{email}</a> or fill out our form - it
            only takes a few minutes!
            </Body>
            <ButtonContainer>
            <Button href={form}>Work with us</Button>
            </ButtonContainer>
        </Content>
    </SectionPad>
)

export default WorkWithUs
